import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/engineering.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'MLM Group',
    url: '/mlm-group',
  },
]

const MlmGroup = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D MLM Group"
      description="R&D MLM Group"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="MLM Group"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="MLM Group" />
          <CaseStudyContent
            heading="Engineering, environmental & building control consultancy"
            paragraph='"Following some initial conversations with Kevin Auchoybur from
            RDA and Jim Rudd, an Infrastructure Director from
            MLM, we looked into the feasibility of submitting a claim on a
            no-win, no-fee basis. Kevin took the time to understand our
            business and met the various key people at MLM to establish
            what information was needed.'
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
                <p>
                  The input required from myself in finance was minimal and Kevin and his experienced team took care of the rest.                
                </p>
                <p>
                  There was further complication in that MLM was also being
                  acquired during this process, and Kevin was available for the
                  various elements that came under review during the
                  due-diligence process, which required virtually immediate
                  responses.
                </p>
                <p>
                  Because of the group structure and number of years covered
                  there were multiple submissions, and we were always kept in
                  the loop on these submissions, likely timescales and
                  probability of success.{' '}
                </p>
                <p>
                  I am pleased to say that we had 100% of our claims settled
                  from HMRC.
                </p>
                <p>
                  In conclusion, I would have no hesitation in recommending
                  Kevin and his team at RDA UK."
                </p>
                <h6>Elliot Wells – FCCA, Financial Director</h6>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default MlmGroup
